import React from 'react';
import { FaYoutube, FaInstagram } from 'react-icons/fa'; // Import YouTube and Instagram icons from react-icons/fa
import './Footer.css'; // Import the CSS file for the footer styles

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-text">
          &copy; 2023 <a href="https://ggulcoding.com/" target="_blank">꿀코딩</a> All rights reserved.
        </p>
        <div className="social-icons">
          <a
            href="https://www.youtube.com/@gunnycoding/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaYoutube />
          </a>
          <a
            href="https://www.instagram.com/gunnycoding/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;