import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { IoCopyOutline } from 'react-icons/io5'; // Import the copy icon from react-icons/io5
import './App.css';

const BACKEND_ENDPOINT = 'https://v5fvpbh2vnlqysf3nvzsefmoua0vihdn.lambda-url.ap-northeast-2.on.aws';
const GGUL_ENDPOINT = 'ggul.ink'

function App() {
  const [url, setUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [error, setError] = useState('');
  const [copyLabel, setCopyLabel] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    // Validate the input
    if (url.trim() === '' || url.length <= 5) {
      setError('올바른 주소를 입력해주세요');
      return;
    }
  
    try {
      const response = await axios.post(`${BACKEND_ENDPOINT}/shorten`, { url });
      setShortUrl(response.data.shortUrl);
    } catch (error) {
      setError('An error occurred. Please try again. - ERF1');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${GGUL_ENDPOINT}/${shortUrl}`);
    setCopyLabel('Copied');
    setTimeout(() => {
      setCopyLabel('');
    }, 2000);
  };

  const handleCopyClick = (e) => {
    e.preventDefault();
    copyToClipboard();
  };

  useEffect(() => {
    const key = window.location.pathname.substring(1); // Extract the key from the URL path
    if (key) {
      // Make a GET request to your backend server with the key
      axios.get(`${BACKEND_ENDPOINT}/${key}`)
        .then((response) => {
          const targetURL = response.data.targetURL;
          window.location.href = targetURL; // Redirect to the targetURL
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  return (
    <div className="Auth-form-container">
      <h3 className="Auth-form-title">꿀URL Shortner</h3>
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <div className="form-group mt-3">
            <label>잘라낼 URL을 넣어주세요</label>
            <input
              type="text"
              className="form-control mt-1"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter URL"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <Button variant="warning" type="submit">
              꿀URL 만들기
            </Button>
          </div>
          {shortUrl && (
            <div className="result-box mt-3">
              <p>Shortened URL:</p>
              <div className="result-url">
                <a href={`https://${GGUL_ENDPOINT}/${shortUrl}`} target="_blank" rel="noopener noreferrer">
                  {`${GGUL_ENDPOINT}/${shortUrl}`}
                </a>
                <div className="copy-icon-container">
                  <button className="copy-icon" onClick={handleCopyClick}>
                    <IoCopyOutline />
                  </button>
                  {copyLabel && <span className="copy-label">{copyLabel}</span>}
                </div>
              </div>
            </div>
          )}
          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </div>
      </form>
      <a id='donate' href="https://toss.me/gunnycode" target="_blank" rel="noopener noreferrer"> 후원하기 </a>
    </div>
  );
}

export default App;